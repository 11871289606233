.tabs-container {
	border: 1px solid #ccc;
	border-radius: 5px;
	width: 860px;
	height: 300px;
	margin: 20px auto;
	font-family: Arial, sans-serif;
}

.tabs-header {
	display: flex;
	border-bottom: 1px solid #ccc;
}

.tab-button {
	flex: 1;
	padding: 10px;
	cursor: pointer;
	background: #f9f9f9;
	border: none;
	outline: none;
	transition: background 0.3s;
}

.tab-button:hover {
	background: #e6e6e6;
}

.tab-button.active {
	background: #fff;
	border-bottom: 2px solid #007bff;
	font-weight: bold;
}

.tabs-content {
	padding: 15px;
	height: 78%;
}

.features {
	list-style: none;
	padding: 0;
}

.features li {
	text-align: left;
	margin: 10px 0;
	display: flex;
	align-items: center;
}

.features li i {
	margin-right: 10px;
	font-size: 20px;
	color: #007bff;
}
