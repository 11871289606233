.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.modal-content {
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
	width: 870px;
	text-align: center;
	position: relative; /* Added for positioning the close button */
}

.close-icon-button {
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	font-size: 20px;
	cursor: pointer;
}

.model-footer {
	display: flex;
	flex-direction: column;
}

.model-close-button {
    align-self: flex-end;
    background-color: var(--link-color) !important;
    font-size: 1rem;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    cursor: pointer;
}
