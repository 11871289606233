@import "../../data/styles.css";

.homepage-logo-container {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 130px;
}

.homepage-container {
	padding-top: 25px;
}

.homepage-first-area {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.homepage-first-area-left-side {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.homepage-title {
	width: 85% !important;
}

.homepage-subtitle {
	width: 85% !important;
}

.homepage-first-area-right-side {
	display: flex;
	align-items: center;
}

.homepage-image-container {
	width: 370px;
	height: 150px;
}

.homepage-image-wrapper {
	overflow: hidden;
	border-radius: 10%;
}

.homepage-image-wrapper img {
	width: 100%;
}

.homepage-works {
	flex-basis: 600px;
	padding-top: 30px;
}

@media (max-width: 1024px) {
	.homepage-first-area {
		flex-direction: column;
	}

	.homepage-first-area-left-side {
		display: flex;
		flex-direction: column; /* Changed: set direction to column */
		align-items: flex-start;
		width: 100%;
		order: 2;
	}

	.homepage-title {
		width: 100% !important;
		margin-top: 20px;
		order: 2;
	}

	.homepage-subtitle {
		width: 100% !important;
		margin-top: 10px;
		order: 3;
	}

	.homepage-first-area-right-side {
		width: 100%;
		order: 1;
	}

	.homepage-image-container {
		padding-top: 50px;
		padding-bottom: 50px;
		margin-left: 10px;
		order: 1;
	}

	.homepage-works {
		flex-basis: auto;
		margin-left: -25px;
		margin-right: -25px;
	}
}
