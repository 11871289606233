@import "../../../data/styles.css";

.project {
	mix-blend-mode: normal;
	height: 100%;
}

.project:hover {
	background: #fafafa;
    box-shadow: rgba(2, 12, 10, 0.7) 10px 10px 18px -15px;
	opacity: 1;
	transition: all 0.3s ease-in-out;
}

.project a {
	text-decoration: none;
}

.project:hover .project-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.project-container {
	padding: 30px;
}

.project-logo {
	display: flex;
	flex-wrap: nowrap;
	gap: 10px;
	overflow-x: auto;
	padding-top: 20px;
}

.project-logo img {
	width: 30px;
}

.project-link {
	padding-top: 10px;
	font-size: 14px;
	color: var(--link-color);
	font-weight: 700;
}

.project-title {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
}

.project-description {
	color: var(--secondary-color);
	font-family: 'Roboto', sans-serif;
    line-height: 1.6;
}

.project-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
}

@media (max-width: 600px) {
	.project {
		height: 300px;
	}
}
