/* App.css */
.app-container {
	padding: 20px;
  }
  
  h1 {
	text-align: center;
  }
  
  .section {
	margin-bottom: 20px;
  }
  
  .tags-section {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
  }
  
  .section-label {
	font-size: 16px;
	font-weight: bold;
	margin-right: 10px; /* space between label and tags */
  }
  
  .tags-list {
	display: flex;
	flex-wrap: wrap;
  }
  
  .tag {
	display: inline-flex;
	align-items: center;
	background-color: #f1f1f1;
	padding: 5px 10px;
	margin: 5px;
	border-radius: 15px;
  }
  
  .tag-label {
	font-size: 14px;
	color: #333;
  }
  
  .tag-remove {
	background: transparent;
	border: none;
	color: #e74c3c;
	font-size: 18px;
	margin-left: 10px;
	cursor: pointer;
  }
  
  .tag-remove:hover {
	color: #c0392b;
  }
  