.flex-container {
    display: flex;
    width: 100%;
}

.right-section {
    flex: 1;
    text-align: left;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.left-section {
    flex: 1;
    text-align: left;
    padding: 10px;
}

.left-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-header {
    color: var(--link-color) !important;
    font-size: 20px;
    font-weight: 500;
}

.video-icon {
    flex: 1;
    background-color: #f9f9f9;
}

.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.description {
    font-size: 14px;
    margin-bottom: auto;
}

.website {
    align-self: flex-start;
    font-size: 12px;
    color: gray;
    margin-top: 10px;
}
