.experiences-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.experiences-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.experiences-list {
	flex-basis: 600px;
	padding-top: 30px;
}

.experiences-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

@media (max-width: 600px) {
	.experiences-container {
		padding-top: 60px;
	}
	
	.experiences-list {
		flex-basis: auto;
		margin-left: -25px;
		margin-right: -25px;
	}
}
